<template>
    <div class="CkManage">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            </div>

            <div slot="header" class="clearfix">
                <div>
                    仪器仪表出库
                </div>
                <el-divider></el-divider>
                <div class="crkType">
                    <!-- <el-radio-group > -->
                    <el-form :inline="true" @submit.native.prevent>
                        <el-form-item label="出库类型" :label-width="formLabelWidth">
                            <!-- <label style="font-size: 14px;">出库类型：</label> -->
                            <el-radio v-model="crkType" v-for="(item, index) in crkTypeList" :key="index"
                                :label="item.code">{{
                                    item.name }}</el-radio>
                        </el-form-item>
                    </el-form>
                    <!-- </el-radio-group> -->
                </div>

                <el-form :inline="true" @submit.native.prevent>
                    <el-form-item label="仪器条码" :label-width="formLabelWidth">
                        <el-input ref="deviceCodeRef" v-model="deviceCode" @keydown.enter.native="courierNumEnterDown"
                            @keyup.enter.native="courierNumEnterUp" placeholder="请输入标签查询" autocomplete="off"
                            class="deviceCode"></el-input>
                        <span class="hint">( 可扫码输入 )</span>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceInfoList" border style="width: 100%; font-size: 13px;" v-loading="loading"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="库房" align="center">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="deviceStatus_text" label="仪器状态" align="center">
                </el-table-column>
                <el-table-column prop="crkStatus_text" label="出入库状态" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="handleDel(scope.row.deviceCode)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="ckInfo">
                <el-form :model="deviceCkInfo" :rules="rules" ref="deviceCkInfo">
                    <div v-if="crkType === '00'">
                        <el-form-item label="试验日期" :label-width="formLabelWidth" prop="testDate">
                            <el-date-picker v-model="deviceCkInfo.testDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择试验日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="站所" :label-width="formLabelWidth" prop="station">
                            <el-input v-model="deviceCkInfo.station" autocomplete="off" class="input" placeholder="请输入站所"></el-input>
                        </el-form-item>
                        <el-form-item label="间隔" :label-width="formLabelWidth" prop="intervalDesc">
                            <el-input v-model="deviceCkInfo.intervalDesc" autocomplete="off" class="input" placeholder="请输入间隔"></el-input>
                        </el-form-item>
                        <el-form-item label="试验类别" :label-width="formLabelWidth" prop="testType">
                            <el-input v-model="deviceCkInfo.testType" autocomplete="off" class="input" placeholder="请输入试验类别"></el-input>
                        </el-form-item>
                        <el-form-item label="试验性质" :label-width="formLabelWidth" prop="testNature">
                            <el-input v-model="deviceCkInfo.testNature" autocomplete="off" class="input" placeholder="请输入试验性质"></el-input>
                        </el-form-item>
                        <el-form-item label="使用期限" :label-width="formLabelWidth" prop="useDeadline">
                            <el-date-picker v-model="deviceCkInfo.useDeadline" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择使用期限">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="试验负责人" :label-width="formLabelWidth" prop="testFzr">
                            <el-input v-model="deviceCkInfo.testFzr" autocomplete="off" class="input" placeholder="请输入试验负责人"></el-input>
                        </el-form-item>
                        <el-form-item label="试验人员" :label-width="formLabelWidth" prop="tester">
                            <el-input v-model="deviceCkInfo.tester" autocomplete="off" class="input" placeholder="请输入试验人员"></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input" placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '01'">
                        <el-form-item label="外送日期" :label-width="formLabelWidth" prop="wsDate">
                            <el-date-picker v-model="deviceCkInfo.wsDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择外送日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="外送人员" :label-width="formLabelWidth" prop="wsPersonnel">
                            <el-input v-model="deviceCkInfo.wsPersonnel" autocomplete="off" class="input"  placeholder="请输入外送人员"></el-input>
                        </el-form-item>
                        <el-form-item label="外送方式" :label-width="formLabelWidth" prop="wsWay">
                            <el-input v-model="deviceCkInfo.wsWay" autocomplete="off" class="input"  placeholder="请输入外送方式"></el-input>
                        </el-form-item>
                        <el-form-item label="是否携带附件" :label-width="formLabelWidth" prop="isXdfj">
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=0>是</el-radio>
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="接收单位" :label-width="formLabelWidth" prop="jsUnit">
                            <el-input v-model="deviceCkInfo.jsUnit" autocomplete="off" class="input"  placeholder="请输入接收单位"></el-input>
                        </el-form-item>
                        <el-form-item label="接收人员" :label-width="formLabelWidth" prop="jsPersonnel">
                            <el-input v-model="deviceCkInfo.jsPersonnel" autocomplete="off" class="input" placeholder="请输入接收人员"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" :label-width="formLabelWidth" prop="contactWay">
                            <el-input v-model="deviceCkInfo.contactWay" autocomplete="off" class="input" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input" placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '02'">
                        <el-form-item label="预警期：" :label-width="formLabelWidth">
                            <span style="color: coral;">{{ check_test }}</span>
                        </el-form-item>
                        <div v-if="caution">
                            <div v-if="caution.substring(1) === '0'">
                                <el-form-item label="提前校验原因" :label-width="formLabelWidth" prop="reason">
                                    <el-input v-model="deviceCkInfo.reason" autocomplete="off" class="input"  placeholder="请输入提前校验原因"></el-input>
                                </el-form-item>
                            </div>
                            <div v-if="caution.substring(1) === '2'">
                                <el-form-item label="超期校验原因" :label-width="formLabelWidth" prop="reason">
                                    <el-input v-model="deviceCkInfo.reason" autocomplete="off" class="input" placeholder="请输入超期校验原因"></el-input>
                                </el-form-item>
                            </div>
                        </div>

                        <el-form-item label="外送日期" :label-width="formLabelWidth" prop="wsDate">
                            <el-date-picker v-model="deviceCkInfo.wsDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择外送日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="外送人员" :label-width="formLabelWidth" prop="wsPersonnel">
                            <el-input v-model="deviceCkInfo.wsPersonnel" autocomplete="off" class="input" placeholder="请输入外送人员"></el-input>
                        </el-form-item>
                        <el-form-item label="外送方式" :label-width="formLabelWidth" prop="wsWay">
                            <el-input v-model="deviceCkInfo.wsWay" autocomplete="off" class="input" placeholder="请输入外送方式"></el-input>
                        </el-form-item>
                        <el-form-item label="是否携带附件" :label-width="formLabelWidth" prop="isXdfj">
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=0>是</el-radio>
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="接收单位" :label-width="formLabelWidth" prop="jsUnit">
                            <el-input v-model="deviceCkInfo.jsUnit" autocomplete="off" class="input" placeholder="请输入接收单位"></el-input>
                        </el-form-item>
                        <el-form-item label="接收人员" :label-width="formLabelWidth" prop="jsPersonnel">
                            <el-input v-model="deviceCkInfo.jsPersonnel" autocomplete="off" class="input" placeholder="请输入接收人员"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" :label-width="formLabelWidth" prop="contactWay">
                            <el-input v-model="deviceCkInfo.contactWay" autocomplete="off" class="input" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input"  placeholder="请输入出库经办人"></el-input>
                        </el-form-item>

                    </div>
                    <div v-if="crkType === '03'">
                        <el-form-item label="借出日期" :label-width="formLabelWidth" prop="jcDate">
                            <el-date-picker v-model="deviceCkInfo.jcDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择借出日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="借出期限" :label-width="formLabelWidth" prop="jcDeadline">
                            <el-date-picker v-model="deviceCkInfo.jcDeadline" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择借出期限">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="借出人员" :label-width="formLabelWidth" prop="jcPersonnel">
                            <el-input v-model="deviceCkInfo.jcPersonnel" autocomplete="off" class="input" placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                        <el-form-item label="审批人员" :label-width="formLabelWidth" prop="approvePersonnel">
                            <el-input v-model="deviceCkInfo.approvePersonnel" autocomplete="off" class="input" placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                        <el-form-item label="是否携带附件" :label-width="formLabelWidth" prop="isXdfj">
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=0>是</el-radio>
                            <el-radio v-model="deviceCkInfo.isXdfj" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item label="接收单位" :label-width="formLabelWidth" prop="jsUnit">
                            <el-input v-model="deviceCkInfo.jsUnit" autocomplete="off" class="input" placeholder="请输入接收单位"></el-input>
                        </el-form-item>
                        <el-form-item label="接收人员" :label-width="formLabelWidth" prop="jsPersonnel" >
                            <el-input v-model="deviceCkInfo.jsPersonnel" autocomplete="off" class="input" placeholder="请输入接收人员"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" :label-width="formLabelWidth" prop="contactWay">
                            <el-input v-model="deviceCkInfo.contactWay" autocomplete="off" class="input" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input" placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '04'">
                        <el-form-item label="转移日期" :label-width="formLabelWidth" prop="zyDate">
                            <el-date-picker v-model="deviceCkInfo.zyDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择转移日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="转移人员" :label-width="formLabelWidth" prop="zyPersonnel">
                            <el-input v-model="deviceCkInfo.zyPersonnel" autocomplete="off" class="input" placeholder="请输入转移人员"></el-input>
                        </el-form-item>
                        <el-form-item label="目标库房" :label-width="formLabelWidth" prop="mbkf">
                            <el-select placeholder="请选择目标库房" v-model="deviceCkInfo.mbkf">
                                <el-option v-for="(item, index) in houseList" :key="index" :label="item.name"
                                    :value="item.code"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="目标位置" :label-width="formLabelWidth" prop="mbLocation">
                            <el-input v-model="deviceCkInfo.mbLocation" autocomplete="off" class="input"  placeholder="请输入转移人员"></el-input>
                        </el-form-item>
                        <el-form-item label="转移原因" :label-width="formLabelWidth" prop="zyCause">
                            <el-input v-model="deviceCkInfo.zyCause" type="textarea" placeholder="请输入不超过100字的原因！"
                                autocomplete="off" class="input" ></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input"  placeholder="请输入出库经办人"></el-input>
                        </el-form-item>
                    </div>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button class="btn" @click="sure('deviceCkInfo')">确 定</el-button>
                    <el-button @click="closeInfo('deviceCkInfo')">取 消</el-button>
                </div>
            </div>


        </el-card>

    </div>
</template>

<script>
import { crkTypeList, queryDeviceInfo, deviceCKRules, ckInventory } from '@/api/deviceCrk.js'
import { deviceStatusList, crkStatusList } from '@/api/device.js'
import { getHouseAll } from '@/api/house.js'
export default {
    data() {
        return {
            crkType: '00',
            crkTypeList: crkTypeList,
            deviceCode: null,
            caution: null,
            check_test: null,
            deviceInfoList: [],
            houseList: [],
            deviceStatusList: deviceStatusList,
            crkStatusList: crkStatusList,
            deviceCkInfo: {
                deviceInfoList: [],
                crkType: this.crkType,
                testDate: null,
                testType: null,
                station: null,
                intervalDesc: null,
                testNature: null,
                useDeadline: null,
                testFzr: null,
                tester: null,
                wsPersonnel: null,
                wsWay: null,
                checkWaring: null,
                reason: null,
                isXdfj: 0,
                wsDate: null,
                jsUnit: null,
                jsPersonnel: null,
                contactWay: null,
                jcDate: null,
                jcDeadline: null,
                jcPersonnel: null,
                approvePersonnel: null,
                zyDate: null,
                zyPersonnel: null,
                mbkf: null,
                mbLocation: null,
                zyCause: null,
                ckJbr: null,
                ckDate: null,
                ckTime: null,
                remark: null
            },
            rules: deviceCKRules,
            loading: false,
            dialogFormVisible: false,
            formLabelWidth: '120px',
            keyDownTime: 0,
            keyUpTime: 0,
        }
    },
    mounted() {
        this.onAuxCodeRef()
        this.loadHouses()
    },
    methods: {
        onAuxCodeRef() {
            this.$nextTick(() => {
                this.$refs.deviceCodeRef.focus()
            })
        },
        async loadHouses() {
            await getHouseAll().then(res => {
                if (res.code === '000000') {
                    this.houseList = res.t
                }
            })
        },

        loadAuxilaryInfo() {
            if (this.deviceCode) {
                if (this.crkType !== '00') {
                    if (this.deviceInfoList.length > 0) {
                        this.$message.warning('该出库类型下，一次只能出库一件仪器！')
                    } else {
                        this.queryDeviceInfo()

                    }
                } else {
                    this.queryDeviceInfo()
                }

            }

        },

        queryDeviceInfo() {
            this.$fullScreenLoading.show("查询中...");
            queryDeviceInfo(this.crkType, this.deviceCode).then(res => {
                setTimeout(() => {
                    // this.loading = false
                    if (res.code === '000000') {
                        if (this.deviceInfoList.length > 0) {
                            if (this.deviceInfoList.findIndex(item => item.deviceCode === res.t.deviceCode) === -1) {
                                this.deviceInfoList.push(res.t)
                            } else {
                                this.$message.warning('该辅助工具已添加到出库清单中，请勿重复添加！')
                            }
                        } else {
                            this.deviceInfoList.push(res.t)
                            this.caution = this.deviceInfoList[0].caution

                            // this.caution === '00' ? this.check_test = '提前校验' : (this.caution === '01' ? this.check_test = '预警校验' : this.check_test = '超期校验')

                            switch (this.caution.substring(1)) {
                                case '0':
                                    this.check_test = '提前校验'
                                    break;
                                case '1':
                                    this.check_test = '预警校验'
                                    break;
                                case '2':
                                    this.check_test = '超期校验'
                                    break;
                                default:
                                    this.check_test = ''
                                    break;
                            }
                        }

                        console.log(this.deviceInfoList)
                        var count = 1;
                        this.deviceInfoList.forEach((item) => {
                            item.seq = count++
                            const index = this.deviceStatusList.findIndex(item1 => item1.code === item.deviceStatus)
                            if (index != -1) {
                                item.deviceStatus_text = this.deviceStatusList[index].name
                            }
                            console.log( "item.crkStatus")
                            console.log( item.crkStatus)
                            const index2 = this.crkStatusList.findIndex(item2 => item2.code === item.crkStatus)
                            if (index2 != -1) {
                                item.crkStatus_text = this.crkStatusList[index2].name
                            }
                        })
                    }
                    this.deviceCode = null
                    this.$fullScreenLoading.hide();
                }, 200)


            })
        },

        //提交出库单
        sure(deviceCkInfo) {
            this.$fullScreenLoading.show("出库中...");
            this.$refs[deviceCkInfo].validate(valid => {
                if (valid) {
                    this.deviceCkInfo.deviceInfoList = this.deviceInfoList
                    this.deviceCkInfo.crkType = this.crkType
                    if (this.crkType !== '00') {
                        this.deviceCkInfo.checkWaring = this.caution
                    }
                    ckInventory(this.deviceCkInfo).then(res => {

                        setTimeout(() => {
                            if (res.code === '000000') {
                                this.$message.success('出库成功')
                            }

                            // this.$refs[deviceCkInfo].resetFields()
                            this.deviceCkInfo = {}
                            this.caution = null
                            this.check_test = ''
                            this.deviceInfoList = []
                            this.$fullScreenLoading.hide();
                            this.$router.go(-1)
                        }, 500)
                    })

                }
            })
        },

        //删除
        handleDel(deviceCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.deviceInfoList.findIndex(item => item.deviceCode === deviceCode)
                if (auxiIndex !== -1) {
                    this.deviceInfoList.splice(auxiIndex, 1)
                    this.caution = null
                    this.check_test = null
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierNumEnterDown() {
            this.keyDownTime = new Date().getTime()
        },
        courierNumEnterUp() {
            this.keyUpTime = new Date().getTime()
            if (this.keyUpTime - this.keyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilaryInfo()
            } else {
                this.loadAuxilaryInfo()
            }
        },

        closeInfo() {
            this.$refs['deviceCkInfo'].resetFields();
            this.deviceInfoList = []
            this.caution = null
            this.check_test = null
        },

    },
    watch: {
        crkType: function () {
            this.onAuxCodeRef()
            this.deviceInfoList = []
            this.caution = null
            this.check_test = null
        }
    }

}


</script>

<style lang="scss" scoped>
.CkManage {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .crkType {
            height: 50px;
            width: 100%;
            text-align: left;
        }
    }


    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;

        .deviceCode {
            width: 600px;
            margin-right: 20px;
        }

        .hint {
            color: red;
        }
    }

    .ckSubmit {
        text-align: right;
    }

    .delColor {
        color: red;
    }

    .ckInfo {
        margin: 30px 0;

        .input {
            width: 60%;
        }

        .dialog-footer {
            text-align: left;
            margin-left: 120px;
        }
    }

}
</style>